(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-id/assets/javascripts/wallet-id.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-id/assets/javascripts/wallet-id.js');
"use strict";


const walletId = {
  WALLET_ID_PLAYER: 'WALLET_ID_PLAYER',
  WALLET_ID_MARKETPLACE_RETAILER: 'WALLET_ID_MARKETPLACE_RETAILER',
  WALLET_ID_SELFSERVICE: 'WALLET_ID_SELFSERVICE'
};
setGlobal('svs.components.tipsen.wallet.walletId', walletId);

 })(window);